/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Reducer } from 'redux';

import { ContextActionTypes, ContextState } from './types';

const initialState: ContextState = {
  userPerson: undefined,
  center: undefined,
  resident: undefined,
  residentTab: undefined,
  openAlerts: undefined,
  activeAlerts: undefined,
  alert: undefined,
  alertTab: undefined,
  drawerIsOpen: false,

  // devices
  allDevices: undefined,
  currentDevice: undefined,
  currentDevicePosition: undefined,

  // cameras
  allCameras: undefined,
  currentCamera: undefined,
  currentCameraPosition: undefined,

  // sensors state
  sensorsStatePolling: false,
  sensorsState: [],
  sensorsStateSensors: [],

  // reports
  reportSensorType: undefined,

  // alert listeners
  listenAlerts: true,

  // push status
  pushStatus: 'none',
};

const reducer: Reducer<ContextState> = (state: ContextState = initialState, action) => {
  switch (action.type) {
    case ContextActionTypes.SET_CENTER: {
      return { ...state, center: action.payload };
    }
    case ContextActionTypes.CLEAR_CENTER: {
      return { ...state, center: null };
    }
    case ContextActionTypes.SET_USER: {
      return { ...state, user: action.payload };
    }
    case ContextActionTypes.SET_RESIDENT: {
      return { ...state, resident: action.payload };
    }
    case ContextActionTypes.CLEAR_RESIDENT: {
      return { ...state, resident: null };
    }
    case ContextActionTypes.SET_OPEN_ALERTS: {
      return { ...state, openAlerts: action.payload };
    }
    case ContextActionTypes.CLEAR_OPEN_ALERTS: {
      return { ...state, openAlerts: undefined };
    }
    case ContextActionTypes.SET_ACTIVE_ALERTS: {
      return { ...state, activeAlerts: action.payload };
    }
    case ContextActionTypes.CLEAR_ACTIVE_ALERTS: {
      return { ...state, activeAlerts: undefined };
    }
    case ContextActionTypes.SET_ALERT: {
      return { ...state, alert: action.payload };
    }
    case ContextActionTypes.CLEAR_ALERT: {
      return { ...state, alert: undefined };
    }
    case ContextActionTypes.SET_ALERT_POSITION: {
      return { ...state, alertPosition: action.payload };
    }
    case ContextActionTypes.CLEAR_ALERT_POSITION: {
      return { ...state, alertPosition: undefined };
    }
    case ContextActionTypes.SET_ALERT_TAB: {
      return { ...state, alertTab: action.payload };
    }
    case ContextActionTypes.CLEAR_ALERT_TAB: {
      return { ...state, alertTab: undefined };
    }
    case ContextActionTypes.SET_RESIDENT_TAB: {
      return { ...state, residentTab: action.payload };
    }
    case ContextActionTypes.CLEAR_RESIDENT_TAB: {
      return { ...state, residentTab: undefined };
    }
    case ContextActionTypes.SET_ACTIVE_RESIDENTS: {
      return { ...state, activeResidents: action.payload };
    }
    case ContextActionTypes.CLEAR_ACTIVE_RESIDENTS: {
      return { ...state, activeResidents: undefined };
    }
    case ContextActionTypes.SET_RESIDENT_POSITION: {
      return { ...state, residentPosition: action.payload };
    }
    case ContextActionTypes.CLEAR_RESIDENT_POSITION: {
      return { ...state, residentPosition: undefined };
    }

    // Alert settings
    case ContextActionTypes.ENABLE_ALERT_LISTENERS: {
      return { ...state, listenersEnabled: true };
    }
    case ContextActionTypes.DISABLE_ALERT_LISTENERS: {
      return { ...state, listenersEnabled: false };
    }
    case ContextActionTypes.SET_SENSORS: {
      return { ...state, allSensorsAlertSettings: action.payload };
    }
    case ContextActionTypes.CLEAR_SENSORS: {
      return { ...state, allSensorsAlertSettings: undefined };
    }
    case ContextActionTypes.SET_SENSOR: {
      return { ...state, alertSettingsViewData: action.payload };
    }
    case ContextActionTypes.CLEAR_SENSOR: {
      return { ...state, alertSettingsViewData: undefined };
    }
    case ContextActionTypes.SET_SENSOR_POSITION: {
      return { ...state, sensorPosition: action.payload };
    }
    case ContextActionTypes.CLEAR_SENSOR_POSITION: {
      return { ...state, sensorPosition: undefined };
    }
    case ContextActionTypes.SET_SELECT_ALERT_TYPE: {
      return { ...state, selectAlertType: action.payload };
    }
    case ContextActionTypes.CLEAR_SELECT_ALERT_TYPE: {
      return { ...state, selectAlertType: undefined };
    }

    // Cameras
    case ContextActionTypes.SET_CAMERAS: {
      return { ...state, allCameras: action.payload };
    }
    case ContextActionTypes.CLEAR_CAMERAS: {
      return { ...state, allCameras: undefined };
    }
    case ContextActionTypes.SET_CAMERA: {
      return { ...state, currentCamera: action.payload };
    }
    case ContextActionTypes.CLEAR_CAMERA: {
      return { ...state, currentCamera: undefined };
    }
    case ContextActionTypes.SET_CAMERA_POSITION: {
      return { ...state, currentCameraPosition: action.payload };
    }
    case ContextActionTypes.CLEAR_CAMERA_POSITION: {
      return { ...state, currentCameraPosition: undefined };
    }

    // Devices settings
    case ContextActionTypes.SET_DEVICES: {
      return { ...state, allDevices: action.payload };
    }
    case ContextActionTypes.CLEAR_DEVICES: {
      return { ...state, allDevices: undefined };
    }
    case ContextActionTypes.SET_DEVICE: {
      return { ...state, currentDevice: action.payload };
    }
    case ContextActionTypes.CLEAR_DEVICE: {
      return { ...state, currentDevice: undefined };
    }
    case ContextActionTypes.SET_DEVICE_POSITION: {
      return { ...state, currentDevicePosition: action.payload };
    }
    case ContextActionTypes.CLEAR_DEVICE_POSITION: {
      return { ...state, currentDevicePosition: undefined };
    }
    case ContextActionTypes.SET_CREATE_DEVICE_TYPE: {
      return { ...state, createDeviceType: action.payload };
    }
    case ContextActionTypes.CLEAR_CREATE_DEVICE_TYPE: {
      return { ...state, createDeviceType: undefined };
    }

    case ContextActionTypes.SET_DRAWER_IS_OPEN: {
      return { ...state, drawerIsOpen: action.payload };
    }

    // sensor's state
    case ContextActionTypes.ENABLE_SENSORS_STATE_POLLING: {
      return { ...state, sensorsStatePolling: true };
    }
    case ContextActionTypes.DISABLE_SENSORS_STATE_POLLING: {
      return { ...state, sensorsStatePolling: false };
    }
    case ContextActionTypes.SET_SENSORS_STATE: {
      return { ...state, sensorsState: action.payload };
    }
    case ContextActionTypes.CLEAR_SENSORS_STATE: {
      return { ...state, sensorsState: [] };
    }
    case ContextActionTypes.SET_SENSORS_STATE_SENSORS: {
      return { ...state, sensorsStateSensors: action.payload };
    }

    // reports
    case ContextActionTypes.SET_REPORT_SENSOR_TYPE: {
      return { ...state, reportSensorType: action.payload };
    }
    case ContextActionTypes.CLEAR_REPORT_SENSOR_TYPE: {
      return { ...state, reportSensorType: undefined };
    }

    // push
    case ContextActionTypes.SET_PUSH_STATUS: {
      return { ...state, pushStatus: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { reducer as contextReducer };
