import { Store as Database } from 'attentive-connect-store/dist';
import * as models from 'attentive-connect-store/dist/models';
import CareCenter from 'attentive-connect-store/dist/models/CareCenter';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Hybrid } from '../hybrid';
import { getLogger } from '../logger';
import * as redux from '../redux';
import { Listener } from 'attentive-connect-store/dist/services/ListenerService';

const logger = getLogger('services/AlertNotificationService', 'info');

interface Props {
  // dispatch props
  setOpenAlerts: typeof redux.context.setOpenAlerts;
  clearOpenAlerts: typeof redux.context.clearOpenAlerts;

  // state (redux) props
  db?: Database;
  center?: CareCenter | null;
  listenAlerts: boolean;
}

type AllProps = Props;

class AlertNotificationService extends React.Component<AllProps> {
  private openAlertsCount = 0;
  private alertListeners = new Map<string, Listener>();

  get alertStatsNameSpace() {
    return 'ui.services.AlertNotificationService.alertsStats';
  }

  get alertsNameSpace() {
    return 'ui.services.AlertNotificationService.alerts';
  }

  async componentDidMount() {
    logger.debug('componentDidMount()', {
      db: !!this.props.db,
      center: !!this.props.center,
      listenAlerts: this.props.listenAlerts,
    });
    await this.stop();
    await this.start();
  }

  async componentDidUpdate(prevProps: AllProps) {
    if (
      this.props.db !== prevProps.db ||
      this.props.center?.id !== prevProps.center?.id ||
      this.props.listenAlerts !== prevProps.listenAlerts
    ) {
      logger.debug('componentDidUpdate()', {
        db: !!this.props.db,
        center: !!this.props.center,
        listenAlerts: this.props.listenAlerts,
      });
      await this.stop();
      await this.start();
    }
  }

  async componentWillUnmount() {
    // logger.trace("will unmount");
    logger.debug('componentWillUnmount()', {
      db: !!this.props.db,
      center: !!this.props.center,
      listenAlerts: this.props.listenAlerts,
    });
    await this.stop();
  }

  debugListeners = () => {
    if (this.props.db) {
      const listeners = this.props.db.listeners
        .getAllListeners()
        .filter((l) => l.nameSpace === this.alertStatsNameSpace);
      listeners.forEach((l) => {
        if (l.nameSpace === this.alertStatsNameSpace) {
          logger.debug('listener', {
            namespace: l.nameSpace,
            id: l.id,
          });
        }
      });
    }
  };

  start = async () => {
    const { db, center, listenAlerts } = this.props;

    if (!center || !db || !listenAlerts) {
      logger.debug('start() - did not start listening', {
        db: !!db,
        center: !!center,
        listenAlerts,
      });

      return;
    }

    logger.debug('start()', { center: center.snapshot.id });
    this.debugListeners();
    await this.startAlertStatsListener();
    await this.updateAlertListeners();
    this.debugListeners();
  };

  startAlertStatsListener = async () => {
    const { db, center } = this.props;
    if (db && center) {
      logger.debug('listenAlertStats()');
      db.listeners.stopAllInNamespace(this.alertStatsNameSpace);
      const listener = await db.alerts.listenAlertStats(
        this.alertStatsNameSpace,
        center,
        async (stats) => {
          logger.debug('alert stats changed', {
            center: center.snapshot.id,
            openAlerts: stats.openAlerts,
          });
          await this.updateOpenAlerts();
        }
      );
      logger.debug('listenAlertStats() - alertStats listener started', {
        namespace: listener.nameSpace,
        center: center.snapshot.id,
      });
    }
  };

  updateAlertListeners = async () => {
    const { db, center } = this.props;
    if (db && center) {
      logger.debug('listenAlertStats()');
      const alerts = await db.alerts.getAlerts(center.snapshot.ref.path);
      for (const alert of alerts) {
        if (!this.alertListeners.has(alert.snapshot.id)) {
          const listener = db.alerts.listen(this.alertsNameSpace, alert, async (alert) => {
            logger.debug('alert changed', {
              center: center.snapshot.id,
              alert: alert.snapshot.id,
            });
            await this.updateOpenAlerts();
          });
          this.alertListeners.set(alert.snapshot.id, listener);
          logger.debug('listenAlerts() - alert listener started', {
            namespace: this.alertsNameSpace,
            center: center.snapshot.id,
            alert: alert.snapshot.id,
          });
        }
      }
      // cleanup listeners
      for (const [alertId, listener] of this.alertListeners) {
        if (!alerts.find((a) => a.snapshot.id === alertId)) {
          db.listeners.stop(listener);
          this.alertListeners.delete(alertId);
        }
      }
    }
  };

  stop = async () => {
    logger.debug('stop()');
    this.stopAlertStatsListeners();
    this.stopAlertListeners();
    this.debugListeners();
  };

  stopAlertStatsListeners = () => {
    const { db } = this.props;
    logger.debug('stopAlertStatsListeners()');
    if (db) {
      db.listeners.stopAllInNamespace(this.alertStatsNameSpace);
    }
    this.debugListeners();
  };

  stopAlertListeners = () => {
    const { db } = this.props;
    logger.debug('stopAlertListeners()');
    if (db) {
      db.listeners.stopAllInNamespace(this.alertsNameSpace);
    }
    this.debugListeners();
  };

  clearOpenAlerts = () => {
    logger.debug('clearing open alerts');
    this.props.clearOpenAlerts();
  };

  updateOpenAlerts = async () => {
    const { db, center } = this.props;
    if (db && center) {
      try {
        logger.debug('updateOpenAlerts()');
        // this.stopAlertListeners();
        const alerts = await this.queryOpenAlerts();
        if (alerts !== undefined) {
          if (alerts.length > 0) {
            logger.debug('[redux] update alert count', { count: alerts.length });
            this.props.setOpenAlerts(alerts);
            if (this.openAlertsCount !== alerts.length) {
              // vibrate if alert count changes
              const hybrid = Hybrid.instance();
              if (hybrid && hybrid.vibrationPlugin) {
                hybrid.vibrationPlugin.vibrate(1000);
              }
              this.openAlertsCount = alerts.length;
            }
          } else {
            logger.debug('[redux] clear open alerts');
            this.props.clearOpenAlerts();
            const hybrid = Hybrid.instance();
            if (hybrid && hybrid.vibrationPlugin) {
              hybrid.vibrationPlugin.vibrate(0);
            }
          }
        }
      } catch (error) {
        logger.error('updateOpenAlerts() error', error);
      } finally {
        await this.updateAlertListeners();
      }
    }
  };

  queryOpenAlerts = async (): Promise<models.AlertWithSensorDetail[] | undefined> => {
    const { db, center } = this.props;
    if (db && center) {
      logger.debug('queryOpenAlerts()');
      const alerts = await db.alerts.getOpenAlerts(center.snapshot.ref.path);
      logger.debug('queryOpenAlerts() [get open alerts]');
      const alertsWithSensorDetail = await Promise.all(
        alerts.map((a) => db.alerts.getAlertWithSensorDetail(a))
      );
      return alertsWithSensorDetail;
    }
    return undefined;
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (application: redux.ApplicationState) => ({
  db: application.auth.database,
  center: application.context.center,
  listenAlerts: application.context.listenAlerts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setOpenAlerts: (alerts: models.AlertWithSensorDetail[]) =>
    dispatch(redux.context.setOpenAlerts(alerts)),
  clearOpenAlerts: () => dispatch(redux.context.clearOpenAlerts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertNotificationService);
