"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCareCenter = exports.handleServiceErrors = void 0;
const logger_1 = require("../logger");
const mappers = __importStar(require("../mappers"));
const models = __importStar(require("../models"));
const aisleepService = __importStar(require("./AisleepService"));
const firebase = __importStar(require("./Firebase"));
const Geocoder_1 = require("./Geocoder");
const timeRangeService = __importStar(require("./TimeRangeService"));
const logger = (0, logger_1.getLogger)('/services/CareCenterService');
const handleServiceErrors = (response) => {
    if (!response.ok) {
        return response.json().then((r) => {
            logger.debug('handleServiceErrors', r);
            const error = {
                error: response.status,
                message: response.statusText,
                response,
            };
            throw error;
        });
    }
    return Promise.resolve(response);
};
exports.handleServiceErrors = handleServiceErrors;
// CareCenterData type guard
const isCareCenterData = (obj) => {
    const data = obj;
    return (data.name !== undefined &&
        data.address !== undefined &&
        data.phone !== undefined &&
        data.residents !== undefined &&
        data.aisleep !== undefined &&
        data.careSystem !== undefined &&
        data.nightTimeRange !== undefined &&
        data.integrationTypes !== undefined &&
        data.manager !== undefined &&
        data.sensors !== undefined &&
        data.nightTimeRange !== undefined &&
        data.alertHandling !== undefined);
};
// CareCenter type guard
const isCareCenter = (obj) => {
    const data = obj;
    return data.snapshot !== undefined && data.data !== undefined && isCareCenterData(data.data);
};
exports.isCareCenter = isCareCenter;
class CareCenterService {
    constructor(db) {
        /**
         * Upgrades data in a care center to the latest version of the data model.
         *
         * @param center
         * @returns
         */
        this.upgradeData = (center) => {
            const data = center.data;
            if (data.domain === undefined) {
                data.domain = null;
            }
            if (data.aisleep === undefined) {
                data.aisleep = null;
            }
            if (data.aisleep !== null && data.aisleep.useProxy === undefined) {
                data.aisleep.useProxy = false;
            }
            if (data.nightTimeRange === undefined) {
                data.nightTimeRange = null;
            }
            if (data.integrationTypes === undefined) {
                data.integrationTypes = [];
            }
            if (data.buddycom === undefined) {
                data.buddycom = null;
            }
            if (data.bonx === undefined) {
                data.bonx = null;
            }
            if (data.manager === undefined) {
                data.manager = null;
            }
            if (data.manager) {
                if (data.manager.firstName === undefined) {
                    data.manager.firstName = null;
                }
                if (data.manager.lastName === undefined) {
                    data.manager.lastName = null;
                }
                if (data.manager.mobile === undefined) {
                    data.manager.mobile = null;
                }
                if (data.manager.phone === undefined) {
                    data.manager.phone = null;
                }
            }
            if (data.alertHandling === undefined) {
                data.alertHandling = models.AlertHandling.ONECLICK;
            }
            if (data.careSystem === undefined) {
                data.careSystem = null;
            }
            data.phone = data.phone === undefined ? null : data.phone;
            data.residents = data.residents === undefined ? null : data.residents;
            data.zip = data.zip === undefined ? null : data.zip;
            data.state = data.state === undefined ? null : data.state;
            data.prefecture = data.prefecture === undefined ? null : data.prefecture;
            data.region = data.region === undefined ? null : data.region;
            data.sensors = data.sensors === undefined ? null : data.sensors;
            data.beds = data.beds === undefined ? null : data.beds;
            data.rooms = data.rooms === undefined ? null : data.rooms;
            data.manager = data.manager === undefined ? null : data.manager;
            data.lat = data.lat === undefined ? null : data.lat;
            data.lng = data.lng === undefined ? null : data.lng;
            data.imageFilename = data.imageFilename === undefined ? null : data.imageFilename;
            data.imageUrl = data.imageUrl === undefined ? null : data.imageUrl;
            data.canEdit = data.canEdit === undefined ? null : data.canEdit;
            data.canSimulate = data.canSimulate === undefined ? null : data.canSimulate;
            data.sensorTypes = data.sensorTypes === undefined ? null : data.sensorTypes;
            data.nightTimeRange = !data.nightTimeRange
                ? models.DEFAULT_TIMERANGE_NIGHT
                : data.nightTimeRange;
            data.tz = data.tz === undefined ? null : data.tz;
            data.alertHandling =
                data.alertHandling === undefined ? models.AlertHandling.ONECLICK : data.alertHandling;
            data.cameraPeekDuration =
                data.cameraPeekDuration === undefined || data.cameraPeekDuration === null
                    ? 30
                    : data.cameraPeekDuration;
            data.cameraAlertDuration =
                data.cameraAlertDuration === undefined || data.cameraAlertDuration === null
                    ? 15 * 60
                    : data.cameraAlertDuration;
            data.pushEnabled = data.pushEnabled === undefined ? false : data.pushEnabled;
            data.pushInterval = data.pushInterval === undefined ? 30 : data.pushInterval;
            data.sensorPushEnabled = data.sensorPushEnabled === undefined ? false : data.sensorPushEnabled;
            data.sensorPushInterval = data.sensorPushInterval === undefined ? 300 : data.sensorPushInterval;
            data.sensorPushUnassigned =
                data.sensorPushUnassigned === undefined ? false : data.sensorPushUnassigned;
            data.careSystem = data.careSystem === undefined ? null : data.careSystem;
            center.data = data;
            return center;
        };
        this.findById = (id) => this.db
            .findById(models.Collection.careCenter, id)
            .then((center) => (center ? this.upgradeData(center) : center));
        this.findByRef = (ref) => this.db
            .findByRef(ref)
            .then((center) => (center ? this.upgradeData(center) : center));
        this.load = (...conditions) => this.db
            .load(models.Collection.careCenter, null, conditions)
            .then((snapshots) => snapshots.map((s) => this.careCenter(s)));
        this.careCenter = (snapshot) => this.upgradeData({
            snapshot,
            id: snapshot.id,
            data: this.db.data(snapshot),
        });
        this.supportsAisleep = (center) => {
            let s = false;
            if (center.sensorTypes) {
                s = center.sensorTypes.find((t) => t === models.SensorType.AISLEEP) !== undefined;
            }
            return s;
        };
        this.supportsSensingWave = (center) => {
            let s = false;
            if (center.sensorTypes) {
                s = center.sensorTypes.find((t) => t === models.SensorType.SENSING_WAVE) !== undefined;
            }
            return s;
        };
        this.supportsCameras = (center) => {
            let s = false;
            if (center.sensorTypes) {
                s = center.sensorTypes.find((t) => t === models.SensorType.CAMERA) !== undefined;
            }
            return s;
        };
        this.validateAisleepServer = (center) => __awaiter(this, void 0, void 0, function* () {
            try {
                const config = center.aisleep;
                if (this.supportsAisleep(center) && config !== null) {
                    return aisleepService.validateServerConfig(this.db, center);
                }
            }
            catch (e) {
                logger.notice('validateAisleepServerConfig', e);
            }
            return false;
        });
        this.supportsBioBeat = (center) => {
            let s = false;
            if (center.data.sensorTypes) {
                s = center.data.sensorTypes.find((t) => t === models.SensorType.BIOBEAT) !== undefined;
            }
            return s;
        };
        this.supportsBLE = (center) => {
            let s = false;
            if (center.data.sensorTypes) {
                s =
                    center.data.sensorTypes.find((t) => t === models.SensorType.BLE_BLOOD_PRESSURE || t === models.SensorType.BLE) !== undefined;
            }
            return s;
        };
        /**
         * Deletes all configured cameras for the care center.
         *
         * @param center
         */
        this.resetCameras = (center) => __awaiter(this, void 0, void 0, function* () {
            const cameras = this.db.camera.getCameras(center);
            yield Promise.all((yield cameras).map((c) => this.db.camera.hardDeleteCamera(c)));
        });
        /**
         * @param center
         * @returns true if the care center can use Buddycom
         */
        this.integratesBuddycom = (center) => center.data.integrationTypes.find((t) => t === models.IntegrationTypes.BUDDYCOM) !== undefined;
        /**
         * @param center
         * @returns true if the care center can use Buddycom and Buddycom is enabled.
         */
        this.buddycomIsEnabled = (center) => this.integratesBuddycom(center) &&
            center.data.buddycom !== null &&
            center.data.buddycom.enabled;
        /**
         * @param center
         * @returns true if the care center can use Bonx
         */
        this.integratesBonx = (center) => center.data.integrationTypes.find((t) => t === models.IntegrationTypes.BONX) !== undefined;
        /**
         * @param center
         * @returns true if the care center can use Bonx and Bonx is enabled.
         */
        this.bonxIsEnabled = (center) => this.integratesBonx(center) && center.data.bonx !== null && center.data.bonx.enabled;
        this.getAllCareCenters = () => this.load();
        this.getCareCenterById = (id) => this.findById(id);
        this.getCareCenterByRef = (ref) => this.findByRef(ref);
        this.addCareCenter = (data) => this.db.add(models.Collection.careCenter, data);
        /**
         * Get all care centers linked to a domain.
         * @param domain the domain id for the care centers.
         * @returns an array of care centers.
         */
        this.getDomainCareCenters = (domain) => __awaiter(this, void 0, void 0, function* () {
            const domainId = typeof domain === 'string' ? domain : domain.id;
            const centers = yield this.load(this.db.where.eq('domain', domainId));
            const ccs = centers.map((c) => this.careCenter(c.snapshot));
            return ccs;
        });
        this.getUserCareCenters = (user) => {
            return this.db.authz.canAccessAllCareCenters(user).then((canAccessAll) => this.load().then((allCareCenters) => {
                const centers = [];
                allCareCenters.forEach((center) => {
                    if (canAccessAll) {
                        centers.push(center);
                    }
                    else {
                        if (user.data.regions &&
                            user.data.regions.find((r) => r === center.data.region) &&
                            !centers.find((c) => c.snapshot.ref.path === center.snapshot.ref.path)) {
                            centers.push(center);
                        }
                        if (user.data.centerRefs &&
                            user.data.centerRefs.find((r) => r === center.snapshot.ref.path) &&
                            !centers.find((c) => c.snapshot.ref.path === center.snapshot.ref.path)) {
                            centers.push(center);
                        }
                    }
                });
                return centers;
            }));
        };
        this.setCareCenter = (id, data) => this.db.set(models.Collection.careCenter, id, data);
        this.updateCareCenter = (center) => __awaiter(this, void 0, void 0, function* () {
            if (!this.supportsCameras(center.data)) {
                // if camera support has been removed, then delete all registered cameras
                yield this.resetCameras(center);
            }
            return yield this.db.update(this.upgradeData(center));
        });
        this.getCareCenterSummary = (center) => __awaiter(this, void 0, void 0, function* () {
            const residents = yield this.db.residents.getActiveResidents(center);
            const sensors = yield this.db.sensors.getCareCenterSensors(center);
            const cameras = yield this.db.camera.getCameras(center);
            const alerts = yield this.db.alerts.getAlertsByStatus(center.snapshot.ref.path, [models.AlertStatusType.OPEN, models.AlertStatusType.RESOLUTION_IN_PROGRESS], []);
            const snoozed = yield this.db.alerts.getAlertsByStatus(center.snapshot.ref.path, [models.AlertStatusType.OPEN_SNOOZED], []);
            const summary = {
                center,
                residents,
                sensors,
                cameras,
                alerts,
                snoozed,
                supportBioBeat: this.supportsBioBeat(center),
                usesBuddycom: this.buddycomIsEnabled(center),
                usesBonx: this.bonxIsEnabled(center),
                supportsCameras: this.supportsCameras(center.data),
            };
            return summary;
        });
        this.getBedOccupancy = (careCenter) => __awaiter(this, void 0, void 0, function* () {
            const api = yield this.db.api();
            const req = {
                center: careCenter.snapshot.id,
            };
            const response = yield api.beds.bedOccupancyGet(req);
            const occupancy = response.map((x) => mappers.bedOccupancyMapper.apiToAc(x));
            return occupancy;
        });
        /**
         * Returns true if the time stamp is considered night time at the care center.
         *
         * @param center
         * @param isoTimestamp
         */
        this.isNightTime = (center, sensors, isoTimestamp) => {
            let isNight = false;
            // only show warning if we have a last in bed time...
            if (isoTimestamp) {
                const nightTimeRange = this.getNightTimeRange(center);
                const tz = center.data.tz ? center.data.tz : 'UTC';
                isNight = timeRangeService.isISOTimeWithinTimeRange(nightTimeRange, isoTimestamp, tz);
                logger.debug({ isNight, isoTimestamp, tz, nightTimeRange });
            }
            return isNight;
        };
        /**
         * The night time time range for the care center.
         *
         * @param center
         */
        this.getNightTimeRange = (center) => center.data.nightTimeRange ? center.data.nightTimeRange : models.DEFAULT_TIMERANGE_NIGHT;
        /**
         * Configure the night time range for the care center.
         * @param center
         * @param nightTimeRange
         */
        this.setNightTimeRange = (center, nightTimeRange) => {
            center.data.nightTimeRange = nightTimeRange;
            return this.updateCareCenter(center);
        };
        /**
         * Configure the push notification settings for the care center.
         * @param center CareCenter the care center to configure
         * @param pushEnabled boolean true if push notifications are enabled
         * @param interval PushIntervalType the interval to use for push notifications
         */
        this.configurePush = (center, pushEnabled, interval) => {
            center.data.pushEnabled = pushEnabled;
            center.data.pushInterval = interval;
            return this.updateCareCenter(center);
        };
        /**
         * During the night time, warnings should be generated
         * whenever a resident is out-of-bed. The default time period is
         * 7PM - 6AM. This time range can be overriden for individual care centers.
         *
         * The local time in the users browser is compared to the time range
         * to determine if warnings should be generated.
         *
         * @param center
         * @param isoTimestamp
         * @see TimeRange.NIGHT
         */
        this.showWarnings = (center, sensors, isoTimestamp) => this.isNightTime(center, sensors, isoTimestamp);
        /**
         * Move a resident to a new care center. The resident and all related
         * alerts are moved. If the resident is attached to any sensors, those
         * are first detached.
         *
         * @param resident the resident
         * @param newCareCenter the new center.
         * @returns the updated resident.
         */
        this.moveResidentToCareCenter = (resident, newCareCenter) => {
            const residentRef = resident.snapshot.ref.path;
            const newCenterRef = newCareCenter.snapshot.ref.path;
            let res = resident;
            if (res.data.careCenterRef === newCenterRef) {
                return Promise.resolve(res);
            }
            // we also need to migrate all of the alerts...
            return this.db.residents
                .detachSensors(res)
                .then((detachedResident) => {
                // detachSensors returns the modified resident
                res = detachedResident;
            })
                .then(() => {
                const cons = [this.db.where.eq('residentRef', residentRef)];
                const q = this.db.alerts.query(cons, []);
                // all alerts for resident also have a careCenterRef to be updated
                return firebase.getDocs(q);
            })
                .then((alerts) => {
                const batches = [];
                let batch = firebase.writeBatch(firebase.FirebaseRuntime.instance.firestore);
                let count = 0;
                alerts.forEach((alert) => {
                    // N.B. a batch can have a max of 500 updates.
                    batch.update(alert.ref, { careCenterRef: newCenterRef });
                    count++;
                    if (count === 500) {
                        batches.push(batch.commit());
                        batch = firebase.writeBatch(firebase.FirebaseRuntime.instance.firestore);
                        count = 0;
                    }
                });
                if (count > 0) {
                    batches.push(batch.commit());
                }
                return Promise.all(batches);
            })
                .then(() => {
                // do this last - after successfull migration of alerts.
                res.data.careCenterRef = newCenterRef;
                res.data.room = null;
                res.data.floor = null;
                res.data.bed = null;
                return this.db.residents.updateResident(res);
            });
        };
        /**
         * This is a soft delete of the care center.
         *
         * @param careCenter
         * @returns The care center with the deleted flag set.
         */
        this.deleteCareCenter = (careCenter) => this.db.delete(careCenter);
        this.getCameraPeekDuration = (careCenter) => careCenter.data.cameraPeekDuration;
        this.getCameraAlertDuration = (careCenter) => careCenter.data.cameraAlertDuration;
        /**
         * Listen for care center changes.
         * @param nameSpace string the name space for the listener. Only one listener per care center per name space is allowed.
         */
        this.listen = (nameSpace, center, changed) => this.db.listeners.listen(nameSpace, center.snapshot, (snapshot) => {
            const _center = this.careCenter(snapshot);
            if (_center.data !== undefined) {
                changed(_center);
            }
        });
        /**
         * Stop listening for care center changes.
         */
        this.stopListening = (listener) => this.db.listeners.stop(listener);
        this.db = db;
    }
    geocodeAddress(careCenter) {
        return new Promise((resolve) => {
            if (!careCenter.address) {
                throw new Error('no address');
            }
            (0, Geocoder_1.geocode)(careCenter.address)
                .then((lnglat) => {
                if (lnglat) {
                    careCenter.lat = lnglat.lat;
                    careCenter.lng = lnglat.lng;
                }
                resolve(careCenter);
            })
                .catch((e) => {
                logger.debug('geocodeAddress', e);
                resolve(careCenter);
            });
        });
    }
}
exports.default = CareCenterService;
