import 'typeface-roboto';
// import './App.css';

import * as React from 'react';

import { CareCenter, UserProfile } from 'attentive-connect-store/dist/models';
import { Database } from 'attentive-connect-store/dist/services';
import { Listener } from 'attentive-connect-store/dist/services/ListenerService';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getSearchParams } from './browser';
import Loading from './components/Loading';
import { WithRouterProps, withRouter } from './hoc/withRouter';
import { getLogger } from './logger';
import * as store from './redux';
import { ACRoutes, routes } from './routes';
import session from './session';
import { ErrorBoundary } from './components/ErrorBoundary';

const logger = getLogger('AppView', 'info');
logger.debug();

interface PropsFromRedux {
  authInitialized: boolean;
  db?: Database;
  userProfile?: UserProfile;
  center?: CareCenter;
  setCenter: typeof store.context.setCenter;
  clearCenter: typeof store.context.clearCenter;
}

interface AppViewProps {
  children?: React.ReactNode;
}

interface State {
  initializing: boolean;
}

type Props = AppViewProps & PropsFromRedux & WrappedComponentProps & WithRouterProps;

class AppView extends React.Component<Props, State> {
  readonly state: State = { initializing: true };
  careCenterListener?: Listener;

  get nameSpace() {
    return 'ui.AppView';
  }

  async componentDidMount() {
    await this.init();
  }

  async componentDidUpdate(prevProps: Props) {
    const { db, center } = this.props;
    // logger.debug('componentDidUpdate');
    if (this.shouldInitialize(prevProps)) {
      logger.debug('componentDidUpdate initialization...');
      await this.init();
    } else if (this.props.center) {
      // check listeners
      if (
        prevProps.center &&
        prevProps.center.snapshot.id !== this.props.center.snapshot.id &&
        db
      ) {
        this.stopListening(db);
      }

      if (this.didSignOut()) {
        logger.debug('componentDidUpdate sign out...');
        if (db) {
          this.stopListening(db);
        }
        this.props.navigate(routes.login, { replace: true });
      } else if (center && db && !this.careCenterListener) {
        logger.debug(`componentDidUpdate listening(${center.data.name})...`);
        this.listen(db, center);
      }
    }
    this.updateLocation();
  }

  async init() {
    const { db, userProfile } = this.props;
    const centerRef = this.getCareCenterRef();
    this.setState({ initializing: true });
    if (db && centerRef) {
      const center = await db.careCenters.findByRef(centerRef);
      if (center && (await db.authz.canAccessCareCenter(center, userProfile))) {
        logger.debug(`initializing: ${center.data.name}`);
        this.props.setCenter(center);
      } else {
        logger.debug(`init: center ${centerRef} not found, redirect to: ${routes.locations}`);
        this.removeCareCenterRef();
        if (!this.isLocationsRoute()) {
          this.props.navigate(routes.locations, { replace: true });
        }
      }
    }
    this.updateLocation();
    this.setState({ initializing: false });
  }

  updateLocation = () => {
    const { location } = this.props;
    if (location && location.pathname) {
      logger.debug(`updateLocation() set most recent: ${location.pathname}+${location.search}`);
      session.location.setMostRecentLocation(location.pathname + location.search);
    }
  };

  shouldInitialize = (prevProps?: Props) => {
    const { db } = this.props;
    return db && (!prevProps || !prevProps.db || this.didSignIn(prevProps));
  };

  didSignIn = (prevProps?: Props) => {
    const { authInitialized } = this.props;
    const did = authInitialized && prevProps && !prevProps.authInitialized;
    if (did) {
      logger.debug('SIGN IN');
    }
    return did;
  };

  didSignOut = (prevProps?: Props) => {
    const { authInitialized } = this.props;
    return !authInitialized && prevProps && prevProps.authInitialized;
  };

  listen = (db: Database, center: CareCenter) => {
    if (logger.isDebugEnabled()) {
      logger.debug(`registering new care center listener: ${center.data.name}`);
    }
    this.careCenterListener = db.careCenters.listen(
      this.nameSpace,
      center,
      (center: CareCenter) => {
        if (logger.isDebugEnabled()) {
          logger.debug(`care center listener called: ${center.data.name}`);
        }
        this.props.setCenter(center);
      }
    );
  };

  stopListening = (db: Database) => {
    if (this.careCenterListener) {
      if (logger.isDebugEnabled()) {
        logger.debug(`removing previous care center listener`);
      }
      db.careCenters.stopListening(this.careCenterListener);
      this.careCenterListener = undefined;
    }
  };

  initializing = () =>
    // (!this.props.authInitialized && !this.isLoginPath()) || this.state.initializing;
    // !this.props.authInitialized && !this.isLoginPath() && this.state.initializing;
    this.state.initializing;

  getCareCenterRef = (): string | null => {
    const searchParams = getSearchParams();

    if (searchParams.careCenterRef) {
      return searchParams.careCenterRef;
    }

    return session.getCareCenterRef();
  };

  removeCareCenterRef = () => {
    session.removeCareCenterRef();
    this.props.clearCenter();
  };

  isLocationsRoute = () => this.props.location.pathname.indexOf(routes.locations) >= 0;
  isLoginRoute = () => this.props.location.pathname.indexOf(routes.login) >= 0;
  isEmptyRoute = () => this.props.location.pathname === '/' || this.props.location.pathname === '';

  loadView = (): React.ComponentType | null => {
    if (this.isLoginRoute() || (this.isEmptyRoute() && !this.careCenterListener)) {
      if (logger.isDebugEnabled()) {
        logger.debug('loadView()', {
          location: this.props.location.pathname,
          isLoginRoute: this.isLoginRoute(),
          isEmptyRoute: this.isEmptyRoute(),
        });
      }
      this.props.navigate(routes.dashboard, { replace: true });
      return null;
    } else {
      const route = ACRoutes.find((m) => {
        const matches = m.match(this.props.location.pathname);
        if (logger.isDebugEnabled() && matches) {
          logger.debug(
            `Location ${this.props.location.pathname} ${
              matches ? 'matches' : 'does not match'
            } route ${m.path}`
          );
        }
        return matches;
      });
      if (route) {
        logger.debug(`loading view: ${route.path}`);
        return route.component;
      }
    }
    return null;
  };

  render() {
    // const { language } = this.props.locale;
    if (this.initializing()) {
      return <Loading />;
    }

    const RouteView = this.loadView();
    if (RouteView) {
      return (
        <ErrorBoundary>
          <RouteView />
        </ErrorBoundary>
      );
    } else {
      logger.debug('redirecting to locations');
      this.props.navigate(routes.locations, { replace: true });
      return <Loading />;
    }
  }
}

// map redux ApplicationState to component properties
const mapStateToProps = (state: store.ApplicationState) => ({
  authInitialized: state.auth.initialized === true,
  db: state.auth.database,
  userProfile: state.auth.userProfile,
  center: state.context.center ? state.context.center : undefined,
});

// map redux dispatch actions to component properties
const mapDispatchToProps = (dispatch: Dispatch) => ({
  setLanguageFromBrowser: () => dispatch(store.locale.setLanguageFromBrowser()),
  setCenter: (center: CareCenter) => dispatch(store.context.setCenter(center)),
  clearCenter: () => dispatch(store.context.clearCenter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(AppView)));
