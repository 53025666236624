import * as models from 'attentive-connect-store/dist/models';
import Camera from 'attentive-connect-store/dist/models/Camera';
import { action } from 'typesafe-actions';

import * as data from '../../data';
import { Push, PushStatus } from '../../push/Push';
import session from '../../session/session';
import { ContextActionTypes } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

export const setUserPerson = (user: models.UserPerson) => action(ContextActionTypes.SET_USER, user);

export const setCenter = (center: models.CareCenter) => {
  session.setCareCenterRef(center.snapshot.ref.path);
  // push notifications for this care center
  if (Push.instance) {
    Push.instance.filterCareCenters([center]);
  }
  return action(ContextActionTypes.SET_CENTER, center);
};
export const clearCenter = () => {
  session.removeCareCenterRef();
  // stop push notifications
  if (Push.instance) {
    Push.instance.filterCareCenters([]);
  }
  return action(ContextActionTypes.CLEAR_CENTER);
};

export const setResident = (resident: models.Resident) =>
  action(ContextActionTypes.SET_RESIDENT, resident);
export const clearResident = () => action(ContextActionTypes.CLEAR_RESIDENT);

/* actively viewed alerts */
export const setActiveResidents = (residents: data.sensors.ResidentViewData[]) =>
  action(ContextActionTypes.SET_ACTIVE_RESIDENTS, residents);
export const clearActiveReisdents = () => action(ContextActionTypes.CLEAR_ACTIVE_RESIDENTS);

export const setResidentPosition = (position: number) =>
  action(ContextActionTypes.SET_RESIDENT_POSITION, position);
export const clearResidentPosition = () => action(ContextActionTypes.CLEAR_RESIDENT_POSITION);

export const setOpenAlerts = (alerts: models.AlertWithSensorDetail[]) =>
  action(ContextActionTypes.SET_OPEN_ALERTS, alerts);
export const clearOpenAlerts = () => action(ContextActionTypes.CLEAR_OPEN_ALERTS);

/* actively viewed alerts */
export const setActiveAlerts = (alerts: data.alerts.AlertViewData[]) =>
  action(ContextActionTypes.SET_ACTIVE_ALERTS, alerts);
export const clearActiveAlerts = () => action(ContextActionTypes.CLEAR_ACTIVE_ALERTS);

export const setAlert = (alert: data.alerts.AlertViewData) =>
  action(ContextActionTypes.SET_ALERT, alert);
export const clearAlert = () => action(ContextActionTypes.CLEAR_ALERT);

export const setAlertPosition = (position: number) =>
  action(ContextActionTypes.SET_ALERT_POSITION, position);
export const clearAlertPosition = () => action(ContextActionTypes.CLEAR_ALERT_POSITION);

export const setAlertTab = (tab: number) => action(ContextActionTypes.SET_ALERT_TAB, tab);
export const clearAlertTab = () => action(ContextActionTypes.CLEAR_ALERT_TAB);

export const setResidentTab = (tab: number) => action(ContextActionTypes.SET_RESIDENT_TAB, tab);
export const clearResidentTab = () => action(ContextActionTypes.CLEAR_RESIDENT_TAB);

export const setSensors = (sensors: data.sensors.AlertSettingsViewData[]) =>
  action(ContextActionTypes.SET_SENSORS, sensors);
export const clearSensors = () => action(ContextActionTypes.CLEAR_SENSORS);

export const setSensor = (sensor: data.sensors.AlertSettingsViewData) =>
  action(ContextActionTypes.SET_SENSOR, sensor);
export const clearSensor = () => action(ContextActionTypes.CLEAR_SENSOR);

export const setSensorPosition = (position: number) =>
  action(ContextActionTypes.SET_SENSOR_POSITION, position);
export const clearSensorPosition = () => action(ContextActionTypes.CLEAR_SENSOR_POSITION);

export const setCameras = (cameras: Camera[]) => action(ContextActionTypes.SET_CAMERAS, cameras);
export const clearCameras = () => action(ContextActionTypes.CLEAR_CAMERAS);

export const setCamera = (camera: Camera) => action(ContextActionTypes.SET_CAMERA, camera);
export const clearCamera = () => action(ContextActionTypes.CLEAR_CAMERA);

export const setCameraPosition = (position: number) =>
  action(ContextActionTypes.SET_CAMERA_POSITION, position);
export const clearCameraPosition = () => action(ContextActionTypes.CLEAR_CAMERA_POSITION);

export const setDevices = (sensors: data.sensors.SensorDeviceData[]) =>
  action(ContextActionTypes.SET_DEVICES, sensors);
export const clearDevices = () => action(ContextActionTypes.CLEAR_DEVICES);

export const setDevice = (sensor: data.sensors.SensorDeviceData) =>
  action(ContextActionTypes.SET_DEVICE, sensor);
export const clearDevice = () => action(ContextActionTypes.CLEAR_DEVICE);

export const setDevicePosition = (position: number) =>
  action(ContextActionTypes.SET_DEVICE_POSITION, position);
export const clearDevicePosition = () => action(ContextActionTypes.CLEAR_DEVICE_POSITION);

export const setCreateDeviceType = (type: models.SensorType) =>
  action(ContextActionTypes.SET_CREATE_DEVICE_TYPE, type);
export const clearCreateDeviceType = () => action(ContextActionTypes.CLEAR_CREATE_DEVICE_TYPE);

export const setDrawerIsOpen = (open: boolean) =>
  action(ContextActionTypes.SET_DRAWER_IS_OPEN, open);

// by slicing the data redux recognizes the array as a new set of data
export const setSensorsStateSensors = (sensors: data.sensors.SensorStateData[]) =>
  action(ContextActionTypes.SET_SENSORS_STATE_SENSORS, sensors.slice());
export const setSensorsState = (sensorsState: data.sensors.SensorStateData[]) =>
  action(ContextActionTypes.SET_SENSORS_STATE, sensorsState.slice());
export const clearSensorsState = () => action(ContextActionTypes.CLEAR_SENSORS_STATE);
export const enableSensorsStatePolling = () =>
  action(ContextActionTypes.ENABLE_SENSORS_STATE_POLLING);
export const disableSensorsStatePolling = () =>
  action(ContextActionTypes.DISABLE_SENSORS_STATE_POLLING);

// reports
export const setReportSensorType = (reportSensorType: models.SensorType) =>
  action(ContextActionTypes.SET_REPORT_SENSOR_TYPE, reportSensorType);
export const clearReportSensorType = () => action(ContextActionTypes.CLEAR_REPORT_SENSOR_TYPE);

// SelectAlertType
export const setSelectAlertType = (selectAlertType: models.AlertType) =>
  action(ContextActionTypes.SET_SELECT_ALERT_TYPE, selectAlertType);
export const clearSelectAlertType = () => action(ContextActionTypes.CLEAR_SELECT_ALERT_TYPE);

// Alert listeners
export const enableAlertListeners = () => action(ContextActionTypes.ENABLE_ALERT_LISTENERS);
export const disableAlertListeners = () => action(ContextActionTypes.DISABLE_ALERT_LISTENERS);

// pussh
export const setPushStatus = (pushStatus: PushStatus) =>
  action(ContextActionTypes.SET_PUSH_STATUS, pushStatus);
